import '../styles/global.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { SSRConfig } from 'next-i18next';
import { appWithTranslation } from 'next-i18next';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { CONDUCTRICS_URI, queryClientProps } from '../utils/env-vars';
import Head from 'next/head';
import type { AppProps as NextAppProps } from 'next/app';
import type { DehydratedState } from '@tanstack/react-query';
import { useManualPageDynatrace } from '@dx-ui/framework-dynatrace';

const APP_NAME = 'dx-offers-ui';

type AppProps = NextAppProps<
  { statusCode: number; dehydratedQueryState: DehydratedState } & SSRConfig
>;

function App({ Component, pageProps, router }: AppProps) {
  useManualPageDynatrace(router.pathname, APP_NAME);

  if (pageProps.statusCode === 404) {
    return <Component {...pageProps} />;
  }
  return (
    <QueryProvider
      {...queryClientProps}
      appName={APP_NAME}
      dehydratedQueryState={pageProps.dehydratedQueryState}
    >
      <Head>
        {CONDUCTRICS_URI && <script src={CONDUCTRICS_URI} async type="text/javascript" />}
        <script src="/modules/assets/vendor/forter" type="text/javascript" async />
      </Head>
      <div dir="ltr">
        <Component {...pageProps} />
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryProvider>
  );
}

export default appWithTranslation<AppProps>(App);
