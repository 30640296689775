import getConfig from 'next/config';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';

const publicEnvVars = getConfig()?.publicRuntimeConfig || {};

export const queryClientProps = getQueryProviderProps();

export const ASSETS_URI = publicEnvVars.ASSETS_URI;
export const AUTOCOMPLETE_URL = publicEnvVars.AUTOCOMPLETE_URL;
const OHW_BASE_URL: string = publicEnvVars.OHW_BASE_URL;
export const CONDUCTRICS_URI = publicEnvVars.CONDUCTRICS_URI;

function trimTrailingSlash(url?: string) {
  return url?.replace(/\/$/, '');
}

export const OHW_BASE_URL_TRIMMED = trimTrailingSlash(OHW_BASE_URL) || '';

export const DX_AUTH_UI = publicEnvVars.DX_AUTH_UI;
